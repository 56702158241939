import React from "react";
import orderBy from 'lodash.orderby';

/**
 * Subnav that appears on subpages driven by WP pages.
 */
class SubNav extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickLink = this.handleClickLink.bind(this);
    this.handleClickSubsubnavParentBtn = this.handleClickSubsubnavParentBtn.bind(this);
    // this.determineWhichPageToShow = this.determineWhichPageToShow.bind(this);
    // this.createSubpagesLookup = this.createSubpagesLookup.bind(this);
    // this.createSubnavHash = this.createSubnavHash.bind(this);
    // this.initialSetUp = this.initialSetUp.bind(this);
    this.hashSwitch = this.hashSwitch.bind(this);
  }

  state = {
    initialStateInitialized: false,
    subSubNavOpenStatus: {},
    grandChildrenData: [],
    subSubnavToOpenOnLoadId: null,
    subSubnavItemToOpenOnLoadSlug: null,
    subSubnavParentBtnClicked: false,
    subSubnavOpenedOnLoad: false,
    noAutoScroll: false
  }

  componentDidUpdate() {
    // console.log(this.state);
    // console.log(this.props);
    if (!this.state.subSubnavOpenedOnLoad && this.props.subSubnavToOpenOnLoadId && this.state.subSubnavParentBtnClicked && !this.props.subSubnavItemToOpenOnLoadSlug && !this.props.contentRendered) {
      const stringifiedSubSubnavId = this.props.subSubnavToOpenOnLoadId.toString();
      const subSubnavItem = document.querySelector(`#subnav button[data-wordpress-id='${stringifiedSubSubnavId}'] + ul.subsubnav > li.subsubnav__item:nth-child(1) > a`);
      subSubnavItem.click();
      this.setState({ subSubnavOpenedOnLoad: true, noAutoScroll: false });
    } else if ((!this.state.subSubnavOpenedOnLoad && this.props.subSubnavToOpenOnLoadId && this.state.subSubnavParentBtnClicked && this.props.subSubnavItemToOpenOnLoadSlug && !this.props.contentRendered)) {
      const stringifiedSubSubnavId = this.props.subSubnavToOpenOnLoadId.toString();
      const subSubnavItems = document.querySelectorAll(`#subnav button[data-wordpress-id='${stringifiedSubSubnavId}'] + ul.subsubnav > li.subsubnav__item > a`);
      let subSubnavItemsIndex = null;

      // console.log(document.querySelectorAll(`#subnav button[data-wordpress-id='${stringifiedSubSubnavId}'] + ul.subsubnav`));

      for (let index = 0; index < subSubnavItems.length; index++) {
        if (this.props.subSubnavItemToOpenOnLoadSlug === subSubnavItems[index].dataset.slug) {
          subSubnavItemsIndex = index;
          break;
        }
      }

      // console.log(subSubnavItemsIndex);

      if (subSubnavItemsIndex !== null) {
        subSubnavItems[subSubnavItemsIndex].click();
      } else {
        subSubnavItems[0].click();
      }

      this.setState({ subSubnavOpenedOnLoad: true, noAutoScroll: false });
    } else if (this.state.noAutoScroll && this.props.subpages[0].wordpress_parent !== 1219) { // TO DO: make this more efficient
      this.setState({ noAutoScroll: false });
    }
  }

  async grandChildrenQuery(id) {
    const response = await fetch(`${process.env.GATSBY_API_URL}/wp-json/wp/v2/pages?parent=${id}&per_page=100&_fields=id,parent,link,slug,title,menu_order`)
    const json = await response.json();
    if (json.length > 0) {
      return json;
    } else {
      console.log("No grandchildren data returned");
    }
  }

  //Call to return the content associated to the subpage
  async childContentQuery(slug) {
    const response = await fetch(`${process.env.GATSBY_API_URL}/wp-json/wp/v2/pages?slug=${slug}`)
    const json = await response.json();
    if (json.length > 0) {
      return json;
    } else {
      console.log("No child content returned");
    }
  }

  hashSwitch() {
    const stringifiedSubSubnavId = this.props.subSubnavToOpenOnLoadId.toString();
    const subSubnavItems = document.querySelectorAll(`#subnav button[data-wordpress-id='${stringifiedSubSubnavId}'] + ul.subsubnav > li.subsubnav__item > a`);
    let subSubnavItemsIndex = null;

    // console.log(document.querySelectorAll(`#subnav button[data-wordpress-id='${stringifiedSubSubnavId}'] + ul.subsubnav`));

    for (let index = 0; index < subSubnavItems.length; index++) {
      if (this.props.subSubnavItemToOpenOnLoadSlug === subSubnavItems[index].dataset.slug) {
        subSubnavItemsIndex = index;
        break;
      }
    }

    // console.log(document.querySelectorAll(`#subnav button[data-wordpress-id='${stringifiedSubSubnavId}'] + ul.subsubnav > li.subsubnav__item > a`));

    if (subSubnavItemsIndex !== null) {
      subSubnavItems[subSubnavItemsIndex].click();
    } else {
      subSubnavItems[0].click();
    }

    this.setState({ subSubnavOpenedOnLoad: true, noAutoScroll: false });
  }

  /**
   * Call parent function that updates state of which content to show
   */
  handleClickLink(e) {
    e.preventDefault();
    this.props.replaceContent();
    const href = e.currentTarget.href;
    // const initialLoad = ((!this.state.subSubnavOpenedOnLoad && this.props.subpages[0].tag === 434) || this.props.active === 0) && true;
    // const parentPageId = this.props.subpages[0].wordpress_parent;
    // const noAutoScroll = this.state.noAutoScroll;

    this.childContentQuery(e.currentTarget.dataset.slug).then(response => {
      this.props.childContent(response);
    })
    this.props.updateState(e.currentTarget.dataset.wordpressid, href.substring(href.indexOf('#')), e.currentTarget.dataset.title);
  }

  /**
   * Toggle subsubnav open or closed when its button is clicked
   */
  handleClickSubsubnavParentBtn(e) {
    const clickedWordpressId = e.currentTarget.dataset.wordpressId;
    let subNavState = this.state.subSubNavOpenStatus;
    let currentGrandChildArray = this.state.grandChildrenData;
    let hashChange = this.props.samePageDiffHash;

    // console.log(hashChange);
    // console.log(clickedWordpressId);


    if (!hashChange || !subNavState[`btn-${clickedWordpressId}`]) {
      // Flip the Boolean of clicked button
      subNavState[`btn-${clickedWordpressId}`] = !subNavState[`btn-${clickedWordpressId}`];
    }

    // Flip the Boolean of clicked button
    // subNavState[`btn-${clickedWordpressId}`] = !subNavState[`btn-${clickedWordpressId}`];

    if (subNavState[`btn-${clickedWordpressId}`] === true && currentGrandChildArray.length < 1) {
      this.grandChildrenQuery(clickedWordpressId).then(response => {
        for (let index = 0; index < response.length; index++) {
          currentGrandChildArray.push(response[index]);
        }

        this.setState({
          subSubNavOpenStatus: subNavState,
          grandChildrenData: currentGrandChildArray,
          subSubnavParentBtnClicked: true
        }, () => {
          if (hashChange) {
            this.hashSwitch();
          }
        });
      });
    } else if (subNavState[`btn-${clickedWordpressId}`] === true && currentGrandChildArray.length > 0) {
      let alreadyCalled = currentGrandChildArray.some((element) => {
        if (element.parent === parseInt(clickedWordpressId)) {
          return element;
        }
      });

      if (alreadyCalled === true) {
        this.setState({ subSubNavOpenStatus: subNavState }, () => {
          if (hashChange) {
            this.hashSwitch();
          }
        });
      } else {
        this.grandChildrenQuery(clickedWordpressId).then(response => {
          for (let index = 0; index < response.length; index++) {
            currentGrandChildArray.push(response[index]);
          }

          this.setState({
            subSubNavOpenStatus: subNavState,
            grandChildrenData: currentGrandChildArray,
          }, () => {
            if (hashChange) {
              this.hashSwitch();
            }
          });
        });
      }
    } else {
      this.setState({ subSubNavOpenStatus: subNavState }, () => {
        if (hashChange) {
          this.hashSwitch();
        }
      });
    }
  }

  render() {
    const props = this.props;

    const children = props.subpages;
    // const grandChildren = props.subSubnavs;
    const grandChildren = this.state.grandChildrenData.length > 0 ? this.state.grandChildrenData : [];
    const activeLink = props.active;
    const subSubnavParentBtnClicked = this.state.subSubnavParentBtnClicked;
    // console.log(props);


    /**
     * Create subnav item
     */
    const createSubnavItem = (child, grandChild) => {
      let item;
      const itemsGrandChildren = grandChild.filter(grandChild => (grandChild.parent === child.wordpress_id));


      if (child.tag !== 434) {
        // it's a typical subnav item (does not have a sub-subnav) if no subpage grandchildren below it
        item = createSubnavLi(child, 'subnav');
      } else if (child.tag === 434 && itemsGrandChildren.length > 0) {
        const expanded = this.state.subSubNavOpenStatus[`btn-${child.wordpress_id}`];

        // it's a subnav item that has a sub-subnav because it has subpage grandchildren below it
        item = <li key={child.slug} className="subnav__item subnav__item--parent">
          <button type="button"
            className={`subnav__link subnav__link--parent ${expanded ? 'subnav__link--parent--active' : ''}`}
            onClick={this.handleClickSubsubnavParentBtn}
            aria-expanded={expanded}
            data-wordpress-id={child.wordpress_id}
            data-wordpress-slug={child.slug}
          >{child.title.rendered ? child.title.rendered.replace(/&#038;/, '&').replace(/&#8211;/, '-').replace(/&#8217;/, "'") : child.title.replace(/&#038;/, '&').replace(/&#8211;/, '-').replace(/&#8217;/, "'")}</button>
          {createSubSubnav(child.wordpress_id, itemsGrandChildren)}
        </li>;
      } else {
        const expanded = this.state.subSubNavOpenStatus[`btn-${child.wordpress_id}`];

        // it's a subnav item that has a sub-subnav because it has subpage grandchildren below it
        item = <li key={child.slug} className="subnav__item subnav__item--parent">
          <button type="button"
            className={`subnav__link subnav__link--parent ${expanded ? 'subnav__link--parent--active' : ''}`}
            onClick={this.handleClickSubsubnavParentBtn}
            aria-expanded={expanded}
            data-wordpress-id={child.wordpress_id}
            data-wordpress-slug={child.slug}
          >{child.title.rendered ? child.title.rendered.replace(/&#038;/, '&').replace(/&#8211;/, '-').replace(/&#8217;/, "'") : child.title.replace(/&#038;/, '&').replace(/&#8211;/, '-').replace(/&#8217;/, "'")}</button>
        </li>;
      }

      return item;
    };

    /**
     * Create a single <li> in subnav or sub-subnav
     */
    const createSubnavLi = (child, theClass) => {
      let listItem;
      let renderedTitle = child.title.rendered;
      let childID = child.id;

      if (renderedTitle) {
        child.title = renderedTitle;
      }

      if (childID) {
        child.wordpress_id = childID;
      }

      // Conditional for temporary gate hours offshoot subnav item 
      if (child.slug === "gate-hours") {
        listItem = <li
          key={child.slug}
          className={`${theClass}__item ${child.slug === "green-button-program" ? "hide" : ""}`}>
          <a href="/port-info/gate-hours/"
            className={`${theClass}__link ${parseInt(child.wordpress_id, 10) === parseInt(activeLink, 10) ? `${theClass}__link--active` : ''}`}
            data-wordpressid={child.wordpress_id}
            data-title={child.title}
            data-slug={child.slug}
            data-subsubnavparentid={theClass === 'subsubnav' ? child.parent : false}>
            {child.title.replace(/&#038;/, '&').replace(/&#8211;/, '-').replace(/&#8217;/, "'")}
            {/* {props.isDocumentsPage &&
            (child.filesTotal >= 0 ? ` (${child.filesTotal})` : ` (${props.documentsGalleriesTotal})`)
          } */}
          </a>
        </li>;
      } else {
        listItem = <li
          key={child.slug}
          className={`${theClass}__item ${child.slug === "green-button-program" ? "hide" : ""}`}>
          <a href={`#${(theClass === 'subnav') ? child.slug : props.createSubnavHash(child.link)}`}
            className={`${theClass}__link ${parseInt(child.wordpress_id, 10) === parseInt(activeLink, 10) ? `${theClass}__link--active` : ''}`}
            data-wordpressid={child.wordpress_id}
            data-title={child.title}
            data-slug={child.slug}
            data-subsubnavparentid={theClass === 'subsubnav' ? child.parent : false}
            onClick={this.handleClickLink}>
            {child.title.replace(/&#038;/, '&').replace(/&#8211;/, '-').replace(/&#8217;/, "'")}
            {/* {props.isDocumentsPage &&
            (child.filesTotal >= 0 ? ` (${child.filesTotal})` : ` (${props.documentsGalleriesTotal})`)
          } */}
          </a>
        </li>;
      }

      return listItem;
    };

    /**
     * Create a sub-subnav
     */
    const createSubSubnav = (parentWpId, grandChildArray) => {
      // create array of sublinks that match the parent, ordered my menu_order in WP
      const sublinks = orderBy(grandChildArray.filter(grandChild => grandChild.parent === parentWpId), ['menu_order']);
      const subSubnav = <ul className="subsubnav">
        {sublinks.map(grandChild => createSubnavLi(grandChild, 'subsubnav'))}
      </ul>;

      return subSubnav;
    };


    return (
      <React.Fragment>
        <h3 className="visuallyhidden">subpage navigation</h3>
        <nav aria-label="subpages">
          <ul id="subnav" className="subnav">
            {
              children.map(child => {
                return (
                  createSubnavItem(child, grandChildren)
                )
              })
            }
          </ul>
        </nav>
      </React.Fragment>
    );
  }
}

export default SubNav;







